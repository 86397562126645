.premium-body {
    width: 90%;
    margin: 16px auto;
    background: linear-gradient(180deg, rgb(237, 33, 33) 13.54%, #ce2944 22.55%, #e8f34b 100%);
    padding: 16px 0;
    border-radius: 16px;

    .title-premium {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #FFFFFF;
        padding-left: 5%;
        padding-bottom: 8px;
    }

    .see-all {
        width: 90%;
        margin-left: 5%;
        border-radius: 20px;
        color: #ED1C24;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        height: 40px;
    }
}