.mobile-nav {
    display: flex;
    gap: 8px;
    height: 56px;
    align-items: center;
    background: #F8F8F8;
    box-shadow: 0.2px 0.5px 1px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    padding-left: 5%;
    padding-right: 5%;
}