.online-games {

    width: 90%;
    margin: 0 auto;

    .games-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
}