.quiz-camp-banner {
    width: 100%;
    border-radius: 10px;
    margin: 20px auto;

    .banner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
}

.camp-banner {
    width: 90%;
    border-radius: 10px;
    margin: 20px auto;

    .banner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
}

.camp-date {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px 24px;

    .date {
        font-size: 1.2rem;
        font-weight: 600;
    }
}

.quiz-camp-date {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 700;

    .date {
        font-size: 14px;
        font-weight: 400;
    }
}

.quiz-camp-title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 700;
}

.camp-play {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;

    .play-btn {
        background-color: red;
        width: 80%;
        border: none;
        color: #fff;
        padding: 10px 20px;
        border-radius: 12px;
        font-size: 1.2rem;
        font-weight: 600;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    .quiz-play-btn {
        background-color: red;
        width: 100%;
        border: none;
        color: #fff;
        padding: 10px 20px;
        border-radius: 12px;
        font-size: 0.9rem;
        font-weight: 600;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    .quiz-term-btn {
        background-color: white;
        width: 100%;
        border: none;
        color: black;
        padding: 10px 20px;
        border-radius: 12px;
        font-size: 0.9rem;
        font-weight: 600;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.score-back-btn {
    background-color: red;
    width: 100%;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: 600;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}


.quiz-prize {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 8px;
    height: 112px;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.05);
    padding: 1rem;

    p:first-child {
        font-weight: 700;
        font-size: 14px;
        color: #333333;
    }

    p:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 135px;
        height: 36px;
        color: white;
        background: red;
        border-radius: 40px;
        font-weight: 700;
        font-size: 16px;
    }

    .quiz-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 66px;
    }
}

.term-margin {
    margin-bottom: 1rem !important;
}