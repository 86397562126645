.footer-mobile {
    padding: 0 5%;
    height: 250px;
    background: #F8F8F8;
    box-shadow: 0.2px 0.5px 1px rgba(0, 0, 0, 0.3);
    border-radius: 24px 24px 0px 0px;
    box-sizing: border-box;
    margin-top: 12px;
    bottom: 0;
    width: 100%;


    .logo-holder {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    img {
        padding-top: 20px;
    }

    .page-list {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;

        ul {
            padding: 0%;
            list-style: none;

            li {
                margin-bottom: 8px;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #1C1B1F;
            }
        }
    }

    .social {
        display: flex;
        gap: 16px;
    }
}