.home-mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F8F8F8;
    box-shadow: 0.2px 0.5px 1px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    height: 48px;
    padding-left: 5%;
    padding-right: 5%;

    .contents {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .home-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ED1C24;
        }
    }
}