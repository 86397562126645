.profile-options {
    width: 90%;
    margin: 20px auto;

    .log-out-tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ED1C24;
    }
}