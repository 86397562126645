.category-mobile-nav {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;

    .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #666666;
    }
}