.leaderboard-list{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.leaderboard-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    height: 54px;

    .leaderboard-list-item-info{
        display: flex;
        flex-direction: column;
        align-items: left;
        font-weight: 500;
        font-size: 16px;
        justify-items: left;
    }

    .leaderboard-list-item-count {
        top: -3px;
        position: relative;
        display: inline-block;
    }
    
    .image-container {
        position: relative;
        display: inline-block;
    }
    
    .image-container img {
        display: block;
    }
    
    .score-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white; /* Adjust the color as needed */
        font-size: 16px; /* Adjust the size as needed */
        font-weight: bold; /* Adjust the weight as needed */
        pointer-events: none; /* This ensures the text doesn't interfere with clicking on the image */
    }
}

.leaderboard-rank-1 {
    background-color: #FCD6D3;
}
