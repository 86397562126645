.top-selling {
    width: 90%;
    margin: 20px auto;

    .top-selling-card {
        background: linear-gradient(134.92deg, #ED1B4D 16.03%, #110E33 93.64%);
        border-radius: 12px;
        padding: 10px;
        box-sizing: border-box;
        margin-right: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        min-height: 335px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }

        .title {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            color: #FFFFFF;
        }

        .btn-white-red {
            box-sizing: border-box;
            padding: 12px 24px;
            gap: 4px;
            height: 44px;
            border: 1px solid #ED1C24;
            border-radius: 8px;
            width: 100%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ED1C24;

            &.rounded {
                border-radius: 25px;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
}