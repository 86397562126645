@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

@font-face {
    font-family: 'Nunito';
    src: url('../../public/font/Nunito.ttf') format('truetype');
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: "nunito";
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
    margin: 0 !important;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}


.main-layout {
    width: 33%;
    margin: 0 auto;

    &.mobile {
        width: 100%;
    }

    &.tablet {
        width: 90%;
    }
}

.red-link {
    font-weight: 600;
    color: #ED1C24;
}

.btn-red-wide {
    padding: 12px 16px;
    gap: 4px;
    box-sizing: border-box;
    height: 48px;
    background: #ED1C24;
    border-radius: 8px;
    width: 100%;

    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #F8F8F8;

    &.disabled {
        background: rgb(204, 60, 65);
        color: #a6bac5;
    }
}


.ant-btn-default:not(:disabled):hover {
    // color: #fff;
    border: none;
}

.btn-white-red-border {
    box-sizing: border-box;
    padding: 12px 24px;
    gap: 4px;
    height: 48px;
    border: 1px solid #ED1C24;
    border-radius: 8px;
    width: 100%;
    background: #fff;
}

.card-title-holder {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;

    .card-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #1C1B1F;
        margin: 0;
    }

    .link {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #ED1C24;
        padding-top: 5px
    }
}

.loader-div {
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-spin .ant-spin-dot-item {
    background-color: #ED1C24;
}

@import"./mobile-nav.scss";
@import"./auth.scss";
@import"./otp.scss";
@import"./small-game-card.scss";
@import "./home-mobile-nav.scss";
@import"./long-gamecard.scss";
@import"./userCardNav.scss";
@import"./subscriptioncard.scss";
@import"./categoryMobileNav.scss";
@import"./navCatCard.scss";

@import"./home/allCategory.scss";
@import"./home/campaignBanner.scss";
@import"./home/onlineGames.scss";
@import"./home/downloadbleCardHolder.scss";

@import"./profile/profile.scss";
@import"./updateProfile.scss";
@import"./subscribe/subscribe-page.scss";
@import"./subscribe/time-tab.scss";

@import"./gamePage.scss";
//dummy
@import"./home/dummy-carousel.scss";
@import "./home/billboard.scss";
@import"./home/most-popular.scss";
@import"./home/top-selling.scss";
@import "./home/allDownloadable.scss";
@import "./home/exclusive.scss";
@import "./home/kids.scss";
@import "./home/premium.scss";

@import "./downloadable-page.scss";

@import"./show-all.scss";

@import"./footer-mobile.scss";
@import"./quiz.scss";
@import"./home/leaderboard.scss";