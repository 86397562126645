.small-game-card {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: var(--grayscale-background, #F8F8F8);
    // box-shadow: 0.2px 0.5px 1px rgba(0, 0, 0, 0.3);

    box-shadow: 0.20000000298023224px 0.5px 1px 0px rgba(0, 0, 0, 0.30);

    backdrop-filter: blur(10px);
    border-radius: 16px;
    width: 48%;
    // height: 230px;
    margin-bottom: 16px;
    padding-bottom: 8px;

    img {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        margin-bottom: 8px;
    }

    .sub-menus {

        width: 90%;
        margin: 0 auto;

        .sm-crd-head {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #131313;
        }

        .btn-red-border {
            box-sizing: border-box;
            height: 32px;
            border: 2px solid #ED1C24;
            border-radius: 16px;
            width: 100%;
            background: #fff;
            bottom: 0;
            margin-top: 8px;
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;
            color: #ED1C24;
            gap: 4px;

            :hover>& {
                background: #eee8e8;
            }
        }
    }
}