.sign {
    padding-top: 8px;
    width: 90%;
    margin: 0 auto;

    .sign-head {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        color: #ED1C24;
    }

    .sign-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #1C1B1F;
    }

    .auth-form {
        margin-top: 20px;
    }

    .menu-2 {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #1C1B1F;
    }

}