.subscription-card {
    width: 90%;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    align-items: center;
    padding: 8px 12px;

    height: 70px;

    box-sizing: border-box;

    background: #ED1C24;
    border-radius: 10px;

    .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
    }

    .desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
    }
}