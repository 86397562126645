.all-downloadable {
    width: 90%;
    margin: 0 auto;
    padding: 16px;
    box-sizing: border-box;
    background: linear-gradient(114.44deg, #433DBD 0%, #BAC2F5 100%);
    border-radius: 10px;
    height: 96px;
    margin-bottom: 16px;

    display: flex;
    justify-content: space-between;

    .contents {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            color: #FFFFFF;
            text-shadow: 0.2px 0.5px 1px rgba(0, 0, 0, 0.3);
            backdrop-filter: blur(10px);
        }

        .btn {
            width: 100px;
            height: 28px;
            background: #F2E9FE;
            border-radius: 16px;

            display: flex;
            justify-content: center;
            align-items: center;

            color: #ED1C24;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
        }
    }
}