.billboard-card {
    width: 90%;
    height: 100%;
    margin: 0 auto;

    .billboard-img {
        display: block;
        margin: auto;
        width: 100%;
        // height: 90%;
        border-radius: 8px;
    }

    .billboard-title {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        padding-top: 8px;
    }
}

.prize-card{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    padding: 14px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;

    .prize-img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

  

}
