.ant-drawer .ant-drawer-body {
    padding: 0;
}

.user-card-nav {
    background: #FEE9EA;
    height: 90px;
    padding: 5%;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    gap: 8px;

    margin-bottom: 12px;

    .user-info {
        margin-top: -2px;

        .name {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #1C1B1F;
        }

        .msisdn {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
        }
    }
}