.exclusive-body {
    width: 90%;
    margin: 0 auto;
    background: linear-gradient(114.44deg, #F73A45 0%, #FF8126 100%);
    padding: 16px 0;
    border-radius: 16px;

    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #FFFFFF;
        padding-left: 5%;
        padding-bottom: 8px;
    }

    .see-all {
        width: 90%;
        margin-left: 5%;
        border-radius: 20px;
        color: #ED1C24;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        height: 40px;
    }
}