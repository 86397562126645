.long-game-card {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 16px;
    gap: 10px;
    width: 49%;
    min-height: 290px;
    height: 300px;
    // background: #842D62;
    box-shadow: 0.2px 0.5px 1px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    color: #fff;
    position: relative;
    margin-bottom: 8px;
    // overflow-y: scroll;

    // -ms-overflow-style: none; // IE and Edge
    // scrollbar-width: none; // Firefox

    // &::-webkit-scrollbar {
    //     display: none; // Chrome, Safari, and Opera
    // }

    img {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        margin-bottom: 8px;
        height: 50%;
    }

    .play-btn {
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        // background: #842D62;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .desc {
        width: 90%;
        margin: 0 auto;
        margin-top: 12px;

        .title {

            font-size: 16px;
            line-height: 22px;
            color: #FFFFFF;
        }

        .details {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;
        }
    }
}