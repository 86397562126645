.downloadable-page {
    width: 90%;
    margin: 0 auto;
    padding-top: 16px;

    .category-card-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 16px;

        .category-card {
            display: flex;
            justify-content: space-between;
            width: 49%;
            height: 72px;
            box-shadow: 0.2px 1px 2px rgba(0, 0, 0, 0.395);
            backdrop-filter: blur(10px);
            border-radius: 8px;
            align-items: center;
            padding: 8px;
            box-sizing: border-box;
            margin-bottom: 8px;
        }
    }
}