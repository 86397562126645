.subscribe-page {
    width: 90%;
    margin: 0 auto;
    padding-top: 8px;

    .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #1C1B1F;
    }

    .red-desc {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #ED1C24;
    }

    .ant-radio-group {
        width: 100%;

        .ant-radio-button-wrapper {
            width: 50%;
            text-align: center;
        }
    }

    .plan-holder {
        width: 100%;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 10px;
        }
    }


}