.quiz-card {
  box-shadow: 2px 2px rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  margin-top: 10px;
  gap: 10px;
  height: 54px;
  width: 100%;
  border: 0;
}

.quiz-time-card {
  box-shadow: 2px 2px rgba(0, 0, 0, 0.05);
  background: rgba(252, 214, 211, 1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 54px;
  width: 100%;
  border: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;

  span:first-child {
    font-size: 1rem;
    font-weight: 400;
  }

  span:last-child {
    font-size: 1rem;
    font-weight: 700;
  }
}

.quiz-container {
  padding: 2px 16px;
  background-color: rgba(248, 248, 248, 1);
}

.quiz-circle {
  border: solid 1px rgba(229, 229, 229, 1);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 700;
}

.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .quiz-background {
    background: white;
    border-radius: 12px;
    margin-top: 1rem;

    .quiz-result {
      background-image: url("~/public/img/quiz/result-banner.svg");
      background-repeat: repeat-x;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      height: 225px;
      width: 100%;

      p {
        font-weight: 400;
        font-size: 20px;
        color: #4D4D4D;
      }

      .quiz-score {
        display: flex;
        align-items: center;
        gap: 0.3rem;

        span:last-child {
          font-weight: 700;
          font-size: 32px;
          color: red;
        }
      }
    }
  }


  .quiz-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 12px 0px 12px 0px;
  }

  .quiz-grid-item {
    &:first-child {
      grid-column: 1 / -1;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.05);
  }

  .quiz-grid-item:hover {
    background-color: rgba(248, 248, 248, 1);
  }
}
