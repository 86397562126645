.all-category {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4px;

    width: 90%;
    margin: 0 auto;

    margin-top: 16px;

    .single-cat-card {
        position: relative;

        img {

            display: block;
        }

        .name {
            position: absolute;
            top: 72.5%;
            left: 50%;
            transform: translate(-50%, -50%);

            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #FFFFFF;

        }
    }
}