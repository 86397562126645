.popular {
    width: 90%;
    margin: 0 auto;
    margin-top: 16px;

    .slug {
        padding-bottom: 8px;
    }

    .popular-card {
        display: flex;
        align-items: center;
        margin: 8px 0;
        border-radius: 10px;
        position: relative;
        height: 165px;

        .play-btn {
            position: absolute;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .img-container {
            width: 50%;

            img {
                width: 100%;
                height: 165px;
                margin-top: 6px;
                object-fit: cover;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }

        .desc-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 50%;
            text-align: center;
            padding: 0 8px;
            box-sizing: border-box;

            .name {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #FFFFFF;
            }

            .desc {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #FFFFFF;
            }
        }
    }
}