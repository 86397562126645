.carousel-holder {

    position: relative;
    background: linear-gradient(180deg, rgba(196, 189, 189, 0.6) 0%, rgba(255, 255, 255, 0.807) 100%);
    padding-bottom: 25px;
    padding-top: 20px;

    .custom-dot-list {
        li button {
            width: 12px;
            height: 6px;
            margin: 0 6px;
            background-color: #999;
            border-radius: 4px;
            border: none;
            outline: none;
            cursor: pointer;

            &.active {
                background-color: #000;
            }
        }

        .react-multi-carousel-dot--active button {
            background-color: #000;
            width: 24px;
        }
    }
}