    .time-tabs {
        width: 100%;
        margin: 0 auto;
        background-color: #FEE9EA;
        border-radius: 30px;
        box-sizing: border-box;
        margin-bottom: 16px;
        margin-top: 16px;

        .time-tabs-inside {
            margin: 0 auto;
            display: flex;
            align-items: center;
            height: 40px;
            padding: 1px;
            width: 100%;
        }

        .time-tabs-menu-section {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 50%;
            height: 100%;
            cursor: pointer;
        }

    }