.nav-cat-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    cursor: pointer;

    .infos {
        display: flex;
        gap: 8px;
        align-items: center;
    }


}