.otp-submenu {
    margin-top: 20px;

    .otp-lg-txt {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #1C1B1F;

        .red-time {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: #ED1C24;
            cursor: pointer;
        }
    }

    .otp-sm-txt {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
    }
}