.update-profile {
    padding-top: 12px;
    width: 90%;
    margin: 0 auto;

    .user-card {
        display: flex;
        align-items: center;
        gap: 12px;

        .info {
            .user-name {
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: #1C1B1F;
            }

            .contact {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #666666;
            }

            .button-holder {
                display: flex;
                gap: 8px;

            }
        }
    }

    .form {
        margin-top: 24px;

        h1 {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: #1C1B1F;
            padding-bottom: 12px;
        }
    }
}