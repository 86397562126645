.game-page {
    width: 90%;
    margin: 0 auto;
    padding-top: 12px;

    .cover {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .intro {
        display: flex;
        gap: 8px;
        width: 100%;
        margin-top: 8px;

        .txt {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            width: 100%;

            p {
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                padding-left: 4px;
                /* identical to box height */
                color: #1C1B1F;
            }

            .action {
                border-radius: 24px;
                width: 100%;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 16px;
            }
        }
    }

    .social {
        margin-top: 16px;
        border-bottom: 1px solid #DCDCDC;

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #666666;
        }

        .icon-holder {
            margin-top: 8px;
            display: flex;
            gap: 8px;
            margin-bottom: 24px;
        }
    }

    .details {
        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #1C1B1F;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #666666;
        }

        .b-txt {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #666666;
            padding-top: 8px;
        }

        ul {
            padding-left: 8%;
            padding-top: 8px;

            li {
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;
                color: #666666;
            }
        }
    }

    .also-like {
        padding-bottom: 16px;

        .game-holder {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
}